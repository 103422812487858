import * as React from "react";
import {Button, Container, Grid, Link, Typography, styled} from "@mui/material";
import {Helmet} from "react-helmet";
import imgLogo from "../images/logo-voyix.jpg";
import imgInterface from "../images/Interface_Colibri_Web_Menu.webp";
import imgHeader from "../images/Header.jpg";
import imgContactless from "../images/contactless-dine-in-scan.webp";
import imgContactlessMenu from "../images/contactless-dine-in-menu.webp";
import imgHome from "../images/Home1.webp";
import {GA4React} from "ga-4-react";


const DivRoot = styled('div')(({theme}) => ({
    backgroundColor: "#ffffff",
    fontFamily: "Raleway",
    fontWeight: 400,
    fontSize: "18px"
}));

const DivBannerMenu = styled('div')(({theme}) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    paddingTop: "22px",
    paddingBottom: "22px",
    [theme.breakpoints.down("sm")]: {
        paddingTop: "15px",
        paddingBottom: "15px"
    }
}));

const ContainerBanner = styled(Container)(({theme}) => ({
    marginBottom: theme.spacing(5),
    paddingLeft: "22px",
    paddingRight: "22px",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: "15px",
        paddingRight: "15px"
    }
}));


const TypographyBannerLinks = styled(Typography)(({theme}) => ({
    fontFamily: "Raleway",
    fontSize: "20px",
    color: theme.palette.primary.main,
    [theme.breakpoints.down("sm")]: {
        fontSize: "18px"
    }
}));

const TypographyCabecalho = styled(Typography)(({theme}) => ({
    marginTop: theme.spacing(2),
    fontFamily: "Raleway",
    fontWeight: 700,
    fontSize: "38px"
}));

const SpanHighLigth = styled('span')(({theme}) => ({
    color: theme.palette.primary.main
}));

const GridImg = styled(Grid)({
    textAlign: "center"
});

const ButtonNcr = styled(Button)(({theme}) => ({
    backgroundColor: theme.palette.primary.main
}));

const GridRodape = styled(Grid)(({theme}) => ({
    textAlign: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
}));


const TypographyRodape = styled(Typography)(({theme}) => ({
    color: "#1D1D1D",
    marginBottom: theme.spacing(2),
    fontWeight: 400,
    fontSize: "16px"
}));


const LogoVoyix = styled("img")(({theme}) => ({
    width: "150px",
    [theme.breakpoints.down("sm")]: {
        width: "100px",
    }
}));


// markup
const IndexPage = () => {
    const ga4react = new GA4React("G-CRVSBH9EG8");

    ga4react.initialize().then(
        (ga4) => {
            ga4.gtag("config", "GA_MEASUREMENT_ID");
            ga4.pageview(window.location.href);
        },
        (err) => {
            console.error(err);
        }
    );

    return (
        <DivRoot>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Web Menu Colibri</title>
            </Helmet>

            <ContainerBanner maxWidth={"xl"} disableGutters={true}>
                <DivBannerMenu>
                    <a href="https://www.ncr.com/br-pt/colibri">
                        <LogoVoyix src={imgLogo} alt={"logo"}/>
                    </a>
                    <TypographyBannerLinks variant={"body1"}>
                        <Link href="/admin/#/cadastro" color="inherit">
                            Cadastre-se
                        </Link>{" "}
                        |{" "}
                        <Link href="/admin/#/" color="inherit">
                            Login
                        </Link>
                    </TypographyBannerLinks>
                </DivBannerMenu>
                <img src={imgHeader} alt={"Cabeçalho"} height={"100%"} width={"100%"}/>
            </ContainerBanner>

            <Container maxWidth={"md"}>
                <Grid container spacing={3} alignContent={"center"} alignItems={"center"}>
                    <Grid item md={12}>
                        <TypographyCabecalho align={"center"} variant={"h4"}>
                            Atendimento seguro e <SpanHighLigth>sem contato</SpanHighLigth>
                        </TypographyCabecalho>
                    </Grid>

                    <GridImg item xs={12} sm={12} md={6}>
                        <img src={imgContactless} height={"232px"} width={"222px"} alt={"Menu sem contato"}/>
                        <img src={imgContactlessMenu} height={"232px"} width={"222px"} alt={"Menu sem contato"}/>
                    </GridImg>

                    <Grid item xs={12} sm={12} md={6}>
                        <p>
                            Proteja seus clientes e funcionários aumentando o giro das mesas e a satisfação dos
                            consumidores. O
                            atendimento sem contato é mais seguro e muito mais rápido para você e seus clientes.
                        </p>
                        <p>
                            Basta imprimir um QR code e fixá-lo nas mesas, no balcão ou no caixa do seu restaurante para
                            que seus
                            clientes possam acessar o seu cardápio diretamente pelo celular.
                        </p>
                        <p>Com apenas 2 passos simples o cliente tem acesso a todo o menu do seu restaurante.</p>

                        <ButtonNcr variant="contained" href="/admin/#/cadastro" color={"primary"}>
                            QUERO CRIAR MEU CARDÁPIO
                        </ButtonNcr>
                    </Grid>

                    <Grid item md={12}>
                        <TypographyCabecalho align={"center"} variant={"h4"}>
                            O Web Menu Colibri é <SpanHighLigth>100% gratuito</SpanHighLigth>
                        </TypographyCabecalho>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} alignItems={"center"}>
                        <p>
                            Através de um site protegido e fácil de usar, você cadastra os itens do seu cardápio, com as
                            fotos,
                            descrição dos pratos e preços.
                        </p>
                        <p>Basta salvar e pronto, seu cardápio já está no ar com uma URL personalizada, acessível pelo
                            QR code.</p>
                        <p>
                            Tudo isso 100% gratuito, sem taxas de adesão nem mensalidades. Clique no botão e crie seu
                            cardápio agora
                            mesmo!
                        </p>
                        <ButtonNcr variant="contained" href="/admin/#/cadastro" color={"primary"}>
                            QUERO CRIAR MEU CARDÁPIO
                        </ButtonNcr>
                    </Grid>

                    <GridImg item xs={12} sm={12} md={6}>
                        <img src={imgInterface} height={"236px"} width={"444px"} alt={"Interface"}/>
                    </GridImg>

                    <Grid item md={12}>
                        <TypographyCabecalho align={"center"} variant={"h4"}>
                            O sistema de PDV mais famoso <SpanHighLigth>do Brasil</SpanHighLigth>
                        </TypographyCabecalho>
                    </Grid>

                    <GridImg item xs={12} sm={12} md={6}>
                        <img src={imgHome} height={"248px"} width={"431px"} alt={"POS"}/>
                    </GridImg>

                    <Grid item xs={12} sm={12} md={6}>
                        <p>O Colibri é a solução para automação de bares e restaurantes mais eficiente do mercado.</p>
                        <p>
                            Facilite o dia a dia do seu restaurante. Aumente o giro das mesas, forneça um atendimento
                            mais rápido,
                            lance pedidos na própria mesa, melhore o controle operacional e fidelize seu cliente. Com o
                            Colibri você
                            controla todas as operações de vendas e retaguarda do seu restaurante.
                        </p>
                        <p>Para contratar o sistema de PDV Colibri entre em contato com a gente!</p>
                    </Grid>

                    <GridRodape item xs={12} sm={12} md={12}>
                        <TypographyRodape align={"center"} variant={"body2"}>
                            <b>NCR VOYIX BRASIL</b>
                            <br/>
                            Tower Bridge Corporate
                            <br/>
                            comercial.canais@ncr.com
                            <br/>
                            Av. Jornalista Roberto Marinho, 85 - 4 andar
                            <br/>
                            São Paulo - SP - 04576-010
                        </TypographyRodape>
                        <ButtonNcr variant="contained" href="/admin/#/cadastro" color={"primary"}>
                            QUERO CRIAR MEU CARDÁPIO
                        </ButtonNcr>
                    </GridRodape>
                </Grid>
            </Container>
        </DivRoot>
    );
};

export default IndexPage;
